/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
// import { Message } from '@api/types/chats';
// import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import environment from '../../environment/environment';

export interface INotificationsCoordinationContext {
  driversRequests: any[];
  travelsRejected: any[];
  setDriversRequests: React.Dispatch<React.SetStateAction<any[]>>;
  setTravelsRejected: React.Dispatch<React.SetStateAction<any[]>>;
}

enum Events {
  DRIVER_CLOSE_SHIFT = 'driver_close_shift',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  DRIVER_APPROVED_SHIFT = 'driver_approved_shift',
  RESERVATION_REJECTED_BY_DRIVER = 'reservation_rejected_by_driver'
}

export const NotificationsCoordinationContext =
  createContext<INotificationsCoordinationContext>({
    driversRequests: [],
    setDriversRequests: () => {},
    travelsRejected: [],
    setTravelsRejected: () => {}
  });

export const NotificationsProvider = ({ children }) => {
  const socket = io(environment.notifications, {
    transports: ['websocket', 'polling']
  });

  const [driversRequests, setDriversRequests] = useState<any[]>([]);
  const [travelsRejected, setTravelsRejected] = useState<any[]>([]);

  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();
    console.log('Socket connected!');

    return () => {
      socket.on(Events.DISCONNECT, () => {
        socket.removeAllListeners();
      });
      console.log('Socket disconnected!');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onDriverCloseShift = (data: any) => {
      setDriversRequests((prev) => [...prev, data]);
    };

    const onDriverApprovedShift = (data: any) => {
      setDriversRequests((prev) => prev.filter((req) => req.id !== data.id));
    };

    const onReservationRejectedByDriver = (data: any) => {
      console.log('data', data);
      setTravelsRejected((prev) => [...prev, data]);
    };

    socket.on(Events.DRIVER_CLOSE_SHIFT, onDriverCloseShift);
    socket.on(Events.DRIVER_APPROVED_SHIFT, onDriverApprovedShift);
    socket.on(
      Events.RESERVATION_REJECTED_BY_DRIVER,
      onReservationRejectedByDriver
    );

    return () => {
      socket.off(Events.DRIVER_CLOSE_SHIFT);
      socket.off(Events.DRIVER_APPROVED_SHIFT);
      socket.off(Events.RESERVATION_REJECTED_BY_DRIVER);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversRequests]);

  return (
    <NotificationsCoordinationContext.Provider
      value={{
        driversRequests,
        setDriversRequests,
        travelsRejected,
        setTravelsRejected
      }}
    >
      {children}
    </NotificationsCoordinationContext.Provider>
  );
};

export const useNotificationsContext = () => {
  return useContext(NotificationsCoordinationContext);
};
