import { FunctionComponent, useState } from 'react';
import { tw } from 'utils/tailwindUtils';
import ContainerMonitor from './DriversMonitor/ContainerMonitor';
import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import DriversRequestsMenu from './DriversRequests/DriversRequestsMenu';
import ItemNotification from './notifications/ItemNotification';
import NotificationsIcon from '@material-ui/icons/NotificationImportantOutlined';
import InfoIcon from '@material-ui/icons/Info';
import { useNotificationsContext } from 'shared/context/notificationContext';
import { MonitorDriversInfo } from '../types/Driver';
import DriversRequestsRejectTravelMenu from './DriversRequests/DriverRequestsRejectTravel';

interface HeaderPageProps {
  setDriverDetail: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      details: ChoferTrabajando | undefined;
    }>
  >;
  monitorDriversInfo: MonitorDriversInfo;
  statusActive: string;
  onFilterByStatusDriver: (state: string) => void;
  isLoadingDrivers: boolean;
  reloadDriverMonitorInfo: () => Promise<void>;
  drivers: ChoferTrabajando[];
}

export enum HeaderTabs {
  MONITOR_DRIVERS = 'MONITOR_DRIVERS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NONE = 'NONE'
}

const HeaderPage: FunctionComponent<HeaderPageProps> = ({
  setDriverDetail,
  monitorDriversInfo,
  drivers,
  isLoadingDrivers,
  onFilterByStatusDriver,
  statusActive,
  reloadDriverMonitorInfo
}) => {
  const { driversRequests } = useNotificationsContext();
  const [tabSelected, setTabSelected] = useState<HeaderTabs>(
    HeaderTabs.MONITOR_DRIVERS
  );

  return (
    <div className="flex flex-1">
      <div className="flex flex-1 flex-col mt-2 gap-0">
        {/* <!--Tabs navigation--> */}
        <ul
          className="w-4/12 pl-8 flex list-none flex-row flex-wrap border-b-0 h-16 mb-2"
          role="tablist"
          data-twe-nav-ref
        >
          <li
            role="presentation"
            className="flex-grow basis-0 text-center h-10 mt-2"
          >
            <span
              className={tw(
                'border-transparent hover:border-transparent focus:border-transparent data-[twe-nav-active]:border-primary data-[twe-nav-active]:text-primary dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:text-primary hover:border-b-4 hover:border-blue-100 text-gray-500 cursor-pointer my-2 block border-x-0 border-b-4 border-t-0 px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight hover:isolate focus:isolate',
                {
                  'border-b-4 border-blue-300 text-gray-700':
                    tabSelected === HeaderTabs.MONITOR_DRIVERS
                }
              )}
              data-twe-toggle="pill"
              data-twe-target="#tab-monitor-drivers"
              data-twe-nav-active
              role="tab"
              aria-controls="tab-monitor-drivers"
              aria-selected="true"
              onClick={() => setTabSelected(HeaderTabs.MONITOR_DRIVERS)}
            >
              Monitor de choferes
            </span>
          </li>
          <li
            role="presentation"
            className="flex-grow basis-0 text-center h-10"
          >
            <span
              className={tw(
                'border-transparent hover:border-transparent hover:bg-neutral-100 focus:border-transparent data-[twe-nav-active]:border-primary data-[twe-nav-active]:text-primary dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:text-primary text-gray-500 hover:border-b-4 hover:border-blue-100 cursor-pointer my-2 block border-x-0 border-b-4 border-t-0 px-7 pb-3.5 pt-6 text-xs font-medium uppercase leading-tight hover:isolate focus:isolate ',
                {
                  'border-b-4 border-blue-300 text-gray-700':
                    tabSelected === HeaderTabs.NOTIFICATIONS,
                  'pt-4': driversRequests.length > 0
                }
              )}
              data-twe-toggle="pill"
              data-twe-target="#tab-notifications-driver"
              role="tab"
              aria-controls="tab-notifications-driver"
              aria-selected="false"
              onClick={() => setTabSelected(HeaderTabs.NOTIFICATIONS)}
            >
              Notificaciones de choferes{' '}
              {driversRequests.length > 0 ? (
                <div className="text-md text-blue-500 rounded-2xl inline-block px-2 py-1 ml-1 bg-blue-200">
                  {driversRequests.length}
                </div>
              ) : null}
            </span>
          </li>
        </ul>

        {/* <!--Tabs content--> */}
        <div className="mb-1 h-auto w-full">
          {tabSelected === HeaderTabs.MONITOR_DRIVERS ? (
            <div
              className={tw(
                'transition-opacity duration-300 ease-linear data-[twe-tab-active]:block pl-8 pr-8 flex-1'
              )}
              id="tab-monitor-drivers"
              role="tabpanel"
              data-twe-tab-active
            >
              <ContainerMonitor
                setDriverDetail={setDriverDetail}
                monitorDriversInfo={monitorDriversInfo}
                drivers={drivers}
                isLoadingDrivers={isLoadingDrivers}
                onFilterByStatusDriver={onFilterByStatusDriver}
                reloadDriverMonitorInfo={reloadDriverMonitorInfo}
                statusActive={statusActive}
              />
            </div>
          ) : null}
          {tabSelected === HeaderTabs.NOTIFICATIONS ? (
            <>
              <div
                className="transition-opacity duration-300 ease-linear data-[twe-tab-active]:block flex-1 pl-4"
                id="tab-notifications-driver"
                role="tabpanel"
              >
                <div className="mt-2 flex flex-row gap-3 w-fit pr-0 mr-7 ml-4 pb-2">
                  <div className="py-2 pl-4 pr-6 bg-white shadow-sm rounded-xl w-full flex gap-1 items-center">
                    <NotificationsIcon
                      style={{ color: '#c1c1c1', fontSize: '28px' }}
                      className={tw({
                        'transform transition-all -rotate-45 ':
                          driversRequests.length > 0
                      })}
                    />
                    <div className="flex flex-col gap-0 flex-1">
                      <DriversRequestsMenu />
                      <ItemNotification title="Otros avisos" count={0} />
                    </div>
                  </div>
                  <div className="py-2 pl-4 pr-6 bg-white shadow-sm rounded-xl w-full flex gap-1 items-center h-fit">
                    <InfoIcon style={{ color: '#c1c1c1', fontSize: '28px' }} />
                    <div className="flex flex-col gap-0 flex-1">
                      <DriversRequestsRejectTravelMenu />
                      <ItemNotification
                        title="Contratiempo en viaje"
                        count={0}
                      />
                      <ItemNotification title="Error al contactar" count={0} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderPage;
