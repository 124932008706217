const staging = window.location.href.includes('admin.llt.kairoxlab.xyz');
const prod = window.location.href.includes('admin.lltraslados.com.ar');

// const staging = false;
// const prod = true;

const prodEnvs = {
  backEnd: 'https://back.lltraslados.com.ar/api/',
  booking: 'https://booking.prod.llt.kairoxlab.xyz/api/v1',
  pricing: 'https://pricing.prod.llt.kairoxlab.xyz/api/v1',
  pricingKeys: 'YgmUPthsFaXNDfQ2EzLltrJLjvBe5V76TbS4nt0pcGCdHkD1i0snwAWKS',
  matching: 'https://matching.prod.llt.kairoxlab.xyz/api/v1',
  matchingKeys: 'SLl4trnJLt0jvBfu3er5Vt376TS4nbS4nt0pcIm0rt4lGCdHkD1i0snwAWKS',
  notifications: 'https://ws.prod.llt.kairoxlab.xyz/'
};
const stagingEnvs = {
  backEnd: 'https://api.llt.kairoxlab.xyz/api/',
  booking: 'https://booking.llt.kairoxlab.xyz/api/v1',
  pricing: 'https://pricing.prod.llt.kairoxlab.xyz/api/v1',
  pricingKeys: 'YgmUPthsFaXNDfQ2EzLltrJLjvBe5V76TbS4nt0pcGCdHkD1i0snwAWKS',
  matching: 'https://matching.llt.kairoxlab.xyz/api/v1',
  matchingKeys: 'SLl4trnJLt0jvBfu3er5Vt3WgMgun6dzsJIrrsN7baxAI9FHNfGqJJltxv',
  notifications: 'https://ws.llt.kairoxlab.xyz/'
};

const localEnvs = {
  backEnd: 'http://localhost:8000/api/',
  booking: 'http://localhost:8001/api/v1',
  pricing: 'http://localhost:8001/api/v1',
  pricingKeys: 'YgmUPthsFaXNDfQ2EzLltrJLjvBe5V76TbS4nt0pcGCdHkD1i0snwAWKS',
  matching: 'https://matching.llt.kairoxlab.xyz/api/v1',
  matchingKeys: 'SLl4trnJLt0jvBfu3er5Vt3WgMgun6dzsJIrrsN7baxAI9FHNfGqJJltxv',
  notifications: 'https://ws.prod.llt.kairoxlab.xyz/'
};

//this is the line
const env = prod ? prodEnvs : staging ? stagingEnvs : localEnvs;

// const env = prod ? prodEnvs : true ? stagingEnvs : localEnvs;

export default env;
